import { LogoImage } from 'customizations'
import { User, MenuApi } from 'api'
import { withRouter } from "react-router"
import { SmartButtonStub, SmartButtonLink } from 'ui/smart-buttons'
import LoginModal from 'ui/public/LoginModal'
import Modal from 'shared/modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons'
import React, { Fragment } from 'react';
import { Popover, OverlayTrigger, Dropdown } from 'react-bootstrap';
import Alerts from './alerts';
import { MissingImage } from '../customizations';

class Navbar extends Component {
  state = { dayTime: 'Day', showSidebar: false, openLoginModal: false , dropdownOpen: false,}

  componentDidMount() {
    this.timer = setInterval(this.tick, 60000)
    this.tick()
    this.loadMenus()
    if(this.queryParams('openSignIn') == 'true')
      this.showLoginModal()
    application.state.on("user:update", () => this.forceUpdate())
  }

  componentWillUnmount() {
    clearInterval(this.timer)
  }

  renderLogo() {
    const { current: { logoUrl } } = User
    if(!logoUrl || logoUrl === "null" )
      return <img className='main-logo' src={LogoImage} />

    return <img className='main-logo' src={logoUrl} />
  }

  queryParams(key) {
    let search = this.props.location.search
    let query = new URLSearchParams(search)
    return query.get(key)
  }


  render() {
    if (application.path.includes('/signup') ||
        application.path.includes('/login') ||
        application.path.includes('/app-downloads') ||
        application.path.includes('/marketplace-signup') ||
        application.path.includes('/sms-opt')
       )
      return null
    let navbar = 'new'
    return (
      <div>
        { this.navbar() }
      </div>
    )
  }

  menuBar(){
    if (application.path.includes('/change-password'))
      return null
    let { menuItems } = this.state
    const eventMenuItems = menuItems && menuItems.filter(menu => menu.item_type === 'Event' && menu.valid );
    const placeMenuItems = menuItems && menuItems.filter(menu => menu.item_type === 'Place' && menu.valid );
    const defaultMenuItems = menuItems && menuItems.filter(menu => menu.valid && ( menu.item_type === null || menu.item_type === undefined) );

    return(
      <Dropdown>
        <Dropdown.Toggle
          show={this.state.dropdownOpen.toString()}
          onToggle={this.toggleDropdown}
          id="dropdown-custom"
          className="d-inline-flex align-items-center font-weight-medium text-decoration-none"
        >
          Community Menu
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu-custom p-4 shadow-lg">
          <div className="row">
            <div className="col-12 col-sm-6 col-md-3">
              <h6 className="font-weight-bold text-uppercase text-secondary">Home</h6>
              {defaultMenuItems &&
                defaultMenuItems.length > 0 &&
                defaultMenuItems.map((menu, index) => (
                  <>
                    <Dropdown.Item
                      eventKey={menu.id}
                      onClick={this.closeDropdown}
                      href={'/app#/' + menu.route}
                      className={classNames("nav-item nav-link px-0 text-muted", {
                        unsupported: menu.unsupported,
                      })}
                    >
                      {menu.name}
                    </Dropdown.Item>
                    {index < defaultMenuItems.length - 1 && <Dropdown.Divider />}
                  </>
                ))}
            </div>

            <div className="col-12 col-sm-6 col-md-3">
              <h6 className="font-weight-bold text-uppercase text-secondary">Event listings</h6>
              {eventMenuItems &&
                eventMenuItems.length > 0 &&
                eventMenuItems.map((menu, index) => (
                  <>
                    <Dropdown.Item
                      eventKey={menu.id}
                      onClick={this.closeDropdown}
                      href={'/app#/' + menu.route}
                      className={classNames("nav-item nav-link px-0 text-muted", {
                        unsupported: menu.unsupported,
                      })}
                    >
                      {menu.name}
                    </Dropdown.Item>
                    {index < eventMenuItems.length - 1 && <Dropdown.Divider />}
                  </>
                ))}
            </div>

            <div className="col-12 col-sm-6 col-md-3">
              <h6 className="font-weight-bold text-uppercase text-secondary">Place listings</h6>
              {placeMenuItems &&
                placeMenuItems.length > 0 &&
                placeMenuItems.map((menu, index) => (
                  <>
                    <Dropdown.Item
                      eventKey={menu.id}
                      onClick={this.closeDropdown}
                      href={'/app#/' + menu.route}
                      className={classNames("nav-item nav-link px-0 text-muted", {
                        unsupported: menu.unsupported,
                      })}
                    >
                      {menu.name}
                    </Dropdown.Item>
                    {index < placeMenuItems.length - 1 && <Dropdown.Divider />}
                  </>
                ))}
            </div>

            <div className="col-12 col-sm-6 col-md-3">
              <h6 className="font-weight-bold text-uppercase text-secondary">Others</h6>
              {App.user &&
                App.user.regular &&
                !this.props.location.search.includes('mobile=true') && (
                  <>
                    <Dropdown.Item
                      eventKey={'settings'}
                      onClick={this.closeDropdown}
                      href='/app#/settings'
                      className={classNames("nav-item nav-link px-0 text-muted")}
                    >
                      Settings
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item
                      eventKey={'settings'}
                      onClick={this.closeDropdown}
                      href="/app#/settings/privacy"
                      className={classNames("nav-item nav-link px-0 text-muted")}
                    >
                      Privacy Settings
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item
                      eventKey={'settings'}
                      onClick={this.closeDropdown}
                      href="/app#/settings/payment"
                      className={classNames("nav-item nav-link px-0 text-muted")}
                    >
                      Payment Settings
                    </Dropdown.Item>
                    <Dropdown.Divider />
                  </>
                )}
              {User.current && User.current.regular && (
                <Dropdown.Item
                  eventKey={'logout'}
                  onClick={this.signOut}
                  href="#"
                  className={classNames("nav-item nav-link px-0 font-weight-bold text-danger")}
                >
                  Log Out
                </Dropdown.Item>
              )}
              {User.current && User.current.isGuestExperience && (
                <>
                  <Dropdown.Item
                    eventKey={'login'}
                    onClick={this.showLoginModal}
                    href="#"
                    className={classNames("nav-item nav-link px-0 font-weight-bold text-primary")}
                  >
                    Log In
                  </Dropdown.Item>
                  <Dropdown.Divider />
                   <Dropdown.Item
                    eventKey={'signup'}
                    onClick={this.showLoginModal}
                    href="/app#/signup"
                    className={classNames("nav-item nav-link px-0 font-weight-bold text-primary")}
                  >
                    Sign Up
                  </Dropdown.Item>
                </>
              )}
            </div>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    )
    
  }

  navbar() {
    return (
      <div className='MainNav'>
        <div className="px-3 py-3 d-flex flex-column flex-lg-row justify-content-between align-items-center">
          <div className="d-flex justify-content-start align-items-center nav-brand">
            { this.renderLogo()}
            <div className="d-none d-lg-block border-left border-secondary mx-3" style={{ height: '40px' }}></div>
            <div className="d-lg-block ml-auto m-lg-0">
              { this.menuBar() }
            </div>
          </div>
          <div className="d-md-flex align-items-center justify-content-end text-secondary">
            <div className="d-lg-flex flex-column flex-lg-row align-items-center">
              { this.renderNavBarForm() }
            </div>
          </div>
        </div>
        {
          User.current && User.current.isGuestExperience &&
          <Modal title='Login' opened={this.state.openLoginModal} onClose={this.hideLoginModal}>
            <LoginModal />
          </Modal>
        }
      </div>

    )
  }

  renderNavBarForm() {
    return (
      <div className="form-inline justify-content-center">
        { User.current && User.current.isGuestExperience &&  !application.path.includes('/community-calendar') && !application.path.includes('/large-screen') && this.loginButton() }
        { User.current && User.current.isGuestExperience &&  !application.path.includes('/community-calendar') && !application.path.includes('/large-screen') && this.requestLoginButton()}
        {
          !application.path.includes('/large-screen') && !application.path.includes('/sms') &&
          <>
            <SmartButtonLink text="Community Calendar" className="primary custom-font-size mb-1 mb-md-0" to="/community-calendar" />
            <SmartButtonLink text="Reservable Locations" className="primary custom-font-size mb-1 mb-md-0" to="/places-calendar" />
            <Alerts />
            <span className="navbar-text sidebar d-flex align-items-center">
              <img width="36" height="36" src={User.current.picture || MissingImage} className='profile-image' />
            </span>
          </>
        }
      </div>
    )
  }

  loginButton() {
    let loginEnabled = App.loginOption.includes('user')
    if (loginEnabled)
      return (
        <SmartButtonStub text="Sign In" buttonType="button" className="primary custom-font-size mb-1 mb-md-0" click={this.showLoginModal}/>
      )
  }

  requestLoginButton() {
    let loginEnabled = App.loginOption.includes('user')
    let signupEnabled = App.data.features.sign_up_enabled
    if (loginEnabled && signupEnabled)
      return (
        <SmartButtonLink text="Create my Account" className="primary custom-font-size mb-1 mb-md-0" to="/signup" />
      )
  }

  renderSidebar() {
    return (
      <nav className={classNames("navbar navbar-light collapse flex-column flex-nowrap align-items-start mw-100 show", {visible: this.state.showSidebar})} id="sidebar">
        <div className="navbar-brand d-flex align-items-center w-100 mr-0 custom-navbar-brand">
          {
            User.current && (User.current.regular || User.current.isGuestExperience) &&
              <div className="user-info d-inline-block">
                <span className="navbar-text sidebar d-flex align-items-center">
                  {User.current.picture &&
                    <img src={User.current.picture} className='user-avatar' />
                  }
                  <span className="user-greeting">
                    {
                      `Good ${this.state.dayTime}, `
                    }
                    {User.current.name}
                  </span>
                </span>
              </div>
          }
          <div className="nvb-toggler d-inline-block ml-auto">
            <div className="navbar-text" onClick={this.toggleSidebar}>
              <FontAwesomeIcon icon={faTimes} size="xl"/>
            </div>
          </div>
        </div>
        { this.renderLinks('flex-nowrap justify-content-start h-100') }
        {
          User.current && User.current.regular &&
          <div className="nav-item my-3 w-100">
            <a className="smart-button primary float-right" href="#" onClick={this.signOut}>Sign Out of Your Account</a>
          </div>
        }
      </nav>
    )
  }

  signOut = () => App.signOut()

  tick = () => {
    let hour = App.now.hour()
    let dayTime =
      hour >= 4 && hour < 12 ? 'Morning' :
      hour >= 12 && hour < 17 ? 'Afternoon' :
      'Evening'
    if (this.state.dayTime != dayTime)
      this.setState({ dayTime })
  }

  toggleSidebar = () => {
    this.setState((state) => ({
      showSidebar: !state.showSidebar,
    }));
  }

  showLoginModal = () => {
    this.setState((state) => ({openLoginModal: true}))
  }
  hideLoginModal = () => {
    console.log(this.state.openLoginModal)
    this.setState((state) => ({openLoginModal: false}))
  }

  toggleDropdown = () => {
    this.setState((prevState) => ({ dropdownOpen: !prevState.dropdownOpen }));
  }

  closeDropdown = () => {
    this.setState({ dropdownOpen: false });
  }


  async loadMenus() {
    this.setState({ menuItems: await MenuApi.get('load') })
  }
}

export default withRouter(Navbar)
