import { Redirect } from 'react-router-dom' 
import { connected, withErrorBoundary, Spinner, NoResults, Formatter, GradientedScroller } from 'shared'
import { EventApi, Park, ParkApi, Menu, User, HighlightApi } from 'api'
import { MissingImage } from 'customizations'
import { TopLevelPage, ModelGrid } from 'ui'
import { SmartButtonFeedList } from 'ui/smart-buttons'
import { ChatIcon } from 'customizations'
import EventCard from './EventCardHomeFeed'
import Announcements from './Announcements'
import ParkCard from '../parks/ParkCard'
import { absolutizePath } from 'shared/util'

HomeFeed = ({ match }) ->
  feedItemId = match.params.id
  [highlights, setHighlights] = React.useState([])
  [secondaryHighlights, setSecondaryHighlights] = React.useState([])

  React.useEffect ->
    HighlightApi.primary_highlights()
      .then (fetchedHighlights) ->
        setHighlights(fetchedHighlights)
      .catch (error) ->
        console.error("Failed to fetch primary highlights:", error)

    HighlightApi.secondary_highlights()
      .then (fetchedSecondaryHighlights) ->
        setSecondaryHighlights(fetchedSecondaryHighlights)
      .catch (error) ->
        console.error("Failed to fetch secondary highlights:", error)
  , []

  groups = EventApi.kick('mineGrouped')
  
  return <Spinner /> unless groups

  <TopLevelPage className="HomeFeed" fullWidth={true} modelId={feedItemId} base="/home">
    <div className="HomeFeedInner">
      <div>
        <div className="px-3 mb-5">
          <div className={'SectionHero position-relative'} data-nc-id="SectionHero">
            <div className="headingSection position-absolute px-5 text-center d-flex flex-column align-items-center w-100">
              <span className="homeFeedSubText font-weight-bold text-red d-block">
                <span dangerouslySetInnerHTML={{__html: Formatter.simpleFormat User.homeFeedSubText}}></span>
              </span>
              <h2 className="homeFeedHeader font-weight-bold">
                { User.homeFeedHeader }
              </h2>
            </div>

            <div className="position-relative homeFeedBanner">
              {if User.homeFeedBanner 
                if User.homeFeedBanner.endsWith('.mp4') or User.homeFeedBanner.endsWith('.webm')
                  <video
                    className="position-absolute w-100 h-80 object-fit-cover homeFeedPicture"
                    src={User.homeFeedBanner}
                    autoPlay={true}
                    loop={true}
                    muted={true}
                    controls={false}
                  />
                else
                  <img
                    className="position-absolute w-100 h-80 object-fit-cover homeFeedPicture"
                    src={User.homeFeedBanner}
                    alt="hero"
                  />
              else
                <div className="position-absolute w-100 h-80 object-fit-cover"></div>
              }
            </div>
          </div>
        </div>
      </div>
      
      <div className="container-fluid">
        <div className="row mb-5 pb-5">
          { highlights?.length > 0 &&
            [
              <div key={0} className="col-12 col-md-4 col-sm-6 col-lg-4 d-flex mb-3">
                <CardCategory name={highlights[0]?.name} image={highlights[0]?.image} place={highlights[0]?.place} />
              </div>,
              <div key={1} className="col-12 col-sm-6 col-md-4 col-lg-4">
                <div className="row">
                  <div className="col-12 mb-3">
                    <CardCategory name={highlights[1]?.name} image={highlights[1]?.image}  place={highlights[1]?.place} />
                  </div>
                  <div className="col-12 mb-3">
                    <CardCategory name={highlights[2]?.name} image={highlights[2]?.image} place={highlights[2]?.place} />
                  </div>
                </div>
              </div>,
              <div key={2} className="col-12 col-sm-6 col-md-4 col-lg-4 d-flex mb-3">
                <CardCategory name={highlights[3]?.name} image={highlights[3]?.image} place={highlights[3]?.place} />
              </div>
            ]
          }
        </div>

        <div className="nc-SectionGridCategoryBox position-relative">
          {secondaryHighlights.length > 0 && (
            <>
              <div
                className={
                  'nc-Section-Heading position-relative d-flex flex-column text-center align-items-center mb-5'
                }
              >
                <div className={'w-100 max-w-50 mx-auto'}>
                  <h2 className="h2 font-weight-bold">
                    Discover Community Happenings and Amenities
                  </h2>
                </div>
              </div>

              <div className="row">
                {secondaryHighlights.map((park, i) => (
                  <div className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-4" key={i}>
                    <Link
                      to={"home/#{park.uid}"}
                      className="nc-CardCategoryBox1 d-flex align-items-center p-3 rounded shadow-sm rounded"
                      data-nc-id="CardCategoryBox1"
                    >
                      <div
                        className="flex-shrink-0 rounded-circle overflow-hidden"
                        style={{ width: '96px', height: '96px' }}
                      >
                        <img
                          src={park.image_preview_url || MissingImage}
                          className="img-fluid"
                          style={{ height: '96px' }}
                          alt={park.name}
                        />
                      </div>

                      <div className="ml-3 flex-grow-1">
                        <h2 className="h5 font-weight-medium mb-1 text-truncate fontColour">
                          {park.name}
                        </h2>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>

        {
          if _.isEmpty(groups)
            <NoResults className='compact'><NoResultsMessage /></NoResults>
          else
            <div className="upcoming-events-container">
              <div className="row justify-content-center parks-header-container">
                <div className="col-md-8">
                  <h1 className="upcoming-events-header">Upcoming Events For You</h1>
                </div>
              </div>
              <GradientedScroller containerClassName="feed-columns row">
                {
                  for date in Object.keys(groups).sort()
                    <section className="feed-column col-sm-4 col-lg-4 col-xl-3" key={date}>
                      <header className="feed-column-header">
                        <b>{Formatter.dateToWeekDay(date, {format: 'full'})}</b>
                        <time dateTime={date}>{Formatter.date(date, 'usDateYYYY')}</time>
                        <div className="feed-column-border">
                          <span className="feed-column-circle"></span>
                          <span className="feed-column-line"></span>
                        </div>
                      </header>
                      <div className="feed-column-body">
                        {
                          for model in groups[date]
                            if model instanceof Park
                              <ParkCard park={model} key={model.uid} base='home' />
                            else
                              <EventCard event={model} key={model.uid} base='home' />
                        }
                      </div>
                    </section>
                }
              </GradientedScroller>
            </div>
        }
      </div>
    </div>
  </TopLevelPage>


NoResultsMessage = ->
  appName = application.assets.get('app_name')
  text = if User.guest
    App.guestPassSettings.welcome_text
  else
   "Curating the #{appName} experience just for you.
    The more you use the app, the more the app learns about you.
    Your personalized #{appName} feed will start appearing here shortly."

  <p dangerouslySetInnerHTML={{__html: Formatter.simpleFormat(text)}}></p>

CardCategory= ({ name, image, place }) ->
  <Link
    to={ "home/#{place.uid}" }
    className='nc-CardCategory position-relative d-flex w-100 group overflow-hidden '
    data-nc-id="CardCategory"
  >
    <div className="embed-responsive embed-responsive-16by9 w-100"></div>
    <img
      src={image || MissingImage} 
      alt={name}
      className="img-fluid position-absolute w-100 h-100"
    />
    <div className="position-absolute fixed-bottom p-3 p-sm-4 text-white">
      <span className="position-absolute w-100 h-100 bg-dark-gradient"></span>
      <h2 className="position-relative text-lg font-weight-bolder">{name}</h2>
    </div>
  </Link>

export default withErrorBoundary connected HomeFeed
